import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import Vconsole from 'vconsole'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'

if (process.env.NODE_ENV == 'development') {
let vConsole = new Vconsole()
Vue.use(vConsole)
}
Vue.use(ElementUI)
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp']
new Vue({
    // vuetify,
    render: (h) => h(App),
    router,
}).$mount("#app");
